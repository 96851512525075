import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const vueDataEl = document.getElementById('vue-data')
const vueData = vueDataEl ? JSON.parse(vueDataEl.text) : {}

export default new Vuex.Store({
  state: {
    agent: vueData.agent,
    agreementEnumFields: {},
    agreements: [],
    applications: [],
    applicationStages: [],
    operations: [],
    cities: [],
  },
  getters: {},
  mutations: {
    setAgreements(state, { agreements }) {
      state.agreements = agreements
    },
    setAgreementEnumFields(state, { enumFields }) {
      state.agreementEnumFields = enumFields
    },
    setApplications(state, { applications }) {
      state.applications = applications
    },
    setApplicationStages(state, { stages }) {
      state.applicationStages = stages
    },
    setOperations(state, { operations }) {
      state.operations = operations
    },
    setCities(state, { cities }) {
      state.cities = cities
    },
  },
  actions: {
    async getAgreements({ commit }) {
      const { data: { agreements, enumFields } } = await axios.get('/api/get_agreements/')
      commit('setAgreements', { agreements })
      commit('setAgreementEnumFields', { enumFields })
    },
    async getApplications({ commit }) {
      const { data: { agreements, applications, stages } } = await axios.get('/api/get_applications/')
      commit('setAgreements', { agreements })
      commit('setApplications', { applications })
      commit('setApplicationStages', { stages })
    },
    async getOperations({ commit }) {
      const { data: { operations } } = await axios.get('/api/get_operations/')
      commit('setOperations', { operations })
    },
    async getCities({ commit }) {
      const { data: { cities } } = await axios.get('/api/get_cities/')
      commit('setCities', { cities })
    },
  },
})
